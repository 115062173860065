import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'

const PagePrivacidad = props => {

  return (
    <Layout {...props}>
      <Seo title='Privacidad' />
      <Divider />
      
     
      <Stack effectProps={{ effect: 'fadeInDown' }}>
      <PageTitle
          header='Política de Privacidad de Cashfy'
          subheader='
          Esta Política de Privacidad describe cómo Cashfy ("nosotros", "nuestro" o "sitio web") recopila, utiliza y protege tu información personal cuando visitas nuestro sitio web y cómo utilizamos esta información en relación con la publicidad de Google AdSense. Tu privacidad es de suma importancia para nosotros, y nos comprometemos a protegerla de acuerdo con las mejores prácticas y políticas de Google AdSense.
          <br><br>
          <b>1. Información que Recopilamos</b><br>
          Cuando visitas Cashfy, Google AdSense puede recopilar información no personal, como las páginas que visitas, la fecha y hora de tu visita, el tipo de navegador que utilizas, la dirección IP de tu dispositivo y otras métricas de uso. Esta información se recopila de forma automática y se utiliza para analizar el tráfico del sitio y mejorar la experiencia del usuario.
          <br><br>
          <b>2. Uso de Cookies</b><br>
          Utilizamos cookies para personalizar el contenido y los anuncios que ves en Cashfy. Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo y que nos permiten recordar tus preferencias y mostrar anuncios relevantes. Puedes controlar las cookies a través de la configuración de tu navegador.
          <br><br>
          <b>3. Publicidad de Google AdSense</b><br>
          Cashfy utiliza Google AdSense para mostrar anuncios relevantes a los visitantes. Google AdSense utiliza cookies para recopilar información sobre tu visita a nuestro sitio web y a otros sitios en línea con el fin de proporcionar anuncios personalizados basados en tus intereses. Puedes administrar tus preferencias de anuncios personalizados visitando la Configuración de Anuncios de Google.
          <br><br>
          <b>4. Compartición de Información</b><br>
          No vendemos, alquilamos ni compartimos tu información personal con terceros sin tu consentimiento, excepto cuando sea necesario para cumplir con la ley u otras solicitudes legales.
          <br><br>
          <b>5. Seguridad de Datos</b><br>
          Tomamos medidas razonables para proteger tus datos personales y mantenerlos seguros. Utilizamos medidas de seguridad técnicas y administrativas para prevenir el acceso no autorizado o la divulgación de tu información.
          <br><br>
          <b>6. Enlaces a Terceros</b><br>
          Nuestro sitio web puede contener enlaces a sitios de terceros. Esta Política de Privacidad no se aplica a esos sitios, por lo que te recomendamos revisar las políticas de privacidad de esos sitios antes de proporcionarles información personal.
          <br><br>
          <b>7. Cambios en esta Política de Privacidad</b><br>
          Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento para reflejar cambios en nuestras prácticas de recopilación y uso de datos. Te recomendamos revisar esta política periódicamente para estar informado sobre cómo protegemos tu privacidad.
          <br><br>
          <b>8. Contacto</b><br>
          Si tienes preguntas o inquietudes sobre esta Política de Privacidad o el uso de tu información personal en Cashfy, no dudes en ponerte en contacto con nosotros a través de la página de contacto en nuestro sitio web.
          <br><br>
          Al usar Cashfy, aceptas nuestra Política de Privacidad y el uso de cookies descritos en esta política. Tu privacidad es importante para nosotros, y nos esforzamos por cumplir con las mejores prácticas y políticas de Google AdSense para garantizar una experiencia segura y relevante para nuestros visitantes.

          '
        />
      </Stack>

    </Layout>
  )
}

export default PagePrivacidad
